import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";
import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from "reactstrap";
import Nav from "../NavBar/Nav";
import { Link, useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import base_url from "../../api/bootapi";
import Swal from "sweetalert2";
import moment from "moment";

const FlightDetailsPage = () => {
  const [activeTab, setActiveTab] = useState("past");
  const [searchData, setSearchData] = useState(null);
  const [backdropopen, setbackdropopen] = useState(false);
  const [defaultAirportOptions, setDefaultAirportOptions] = useState([]);
  const [defaultAirlineOptions, setDefaultAirlineOptions] = useState([]);
  const [pastFlightData, setPastFlightData] = useState(null);
  const [airport, setAirport] = useState(null);
  const [airline, setAirline] = useState(null);
  const [delay, setDelay] = useState(null);

  useEffect(() => {
    fetchInitialAirports();
  }, []);

  const tabs = [
    {
      id: "past",
      label: "Flight Details",
    },
    { id: "future", label: "Future Details" },
  ];

  const formatTime = (dateString, timezone) => {
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      timeZone: timezone,
      timeZoneName: "short",
    };
    return new Date(dateString).toLocaleTimeString("en-US", options);
  };

  const pastForm = useForm();
  const futureForm = useForm();

  const {
    register: pastRegister,
    handleSubmit: handlePastSubmit,
    formState: { errors: pastErrors },
    reset: resetPastForm,
    control: airlineControl,
  } = pastForm;

  const {
    register: futureRegister,
    handleSubmit: handleFutureSubmit,
    formState: { errors: futureErrors },
    reset: resetFutureForm,
    control: airportControl,
  } = futureForm;

  const handleTabClick = (tabId) => {
    setPastFlightData(null);
    pastForm.reset();
    futureForm.reset();
    setActiveTab(tabId);
  };

  const loadAirlineOptions = (inputValue, callback) => {
    fetchAirlines(inputValue).then((options) => callback(options));
  };
  const loadAirportOptions = (inputValue, callback) => {
    fetchAirports(inputValue).then((options) => callback(options));
  };

  const fetchAirports = async (inputValue) => {
    if (inputValue.length > 0) {
      try {
        const response = await axios.get(
          `${base_url.api1}/tourapp/airport_list?search=${inputValue}`
        );
        if (response.data && response.data.Airports) {
          return response.data.Airports.map((airport) => ({
            value: airport.id,
            label: airport.airport_name,
          }));
        }
      } catch (error) {
        console.error("Error fetching airports:", error);
      }
    }
    return [];
  };

  const fetchAirlines = async (inputValue) => {
    if (inputValue.length > 0) {
      try {
        const response = await axios.get(
          `${base_url.api1}/tourapp/airline_list?search=${inputValue}`
        );
        if (response.data && response.data.airlines) {
          return response.data.airlines.map((airline) => ({
            value: airline.id,
            label: airline.airline_name,
            hub_code: airline.iata_code,
          }));
        }
      } catch (error) {
        console.error("Error fetching airlines:", error);
      }
    }
    return [];
  };

  const fetchInitialAirports = async () => {
    try {
      // Fetch initial airline options
      const airlineResponse = await axios.get(
        `${base_url.api1}/tourapp/airline_list`
      );
      if (airlineResponse.data && airlineResponse.data.airlines) {
        const airlineOptions = airlineResponse.data.airlines.map((airline) => ({
          value: airline.id,
          label: airline.airline_name,
          hub_code: airline.iata_code,
        }));
        setDefaultAirlineOptions(airlineOptions);
      }

      const response = await axios.get(`${base_url.api1}/tourapp/airport_list`);
      if (response.data && response.data.Airports) {
        const options = response.data.Airports.map((airport) => ({
          value: airport.id,
          label: airport.airport_name,
        }));

        setDefaultAirportOptions(options);
      }
    } catch (error) {
      console.error("Error fetching initial airports:", error);
    }
  };

  function calculateFlightDelay(flight) {
    // Extract arrival times from the flight object
    const actualArrivalTime = flight?.arrival?.actual;
    const originalArrivalTime = flight?.arrival?.scheduled;

    // Check if both times exist
    if (!actualArrivalTime || !originalArrivalTime) {
      return "Delay information not available";
    }

    // Calculate the delay using moment.js
    const duration = moment.duration(
      moment(actualArrivalTime).diff(moment(originalArrivalTime))
    );

    const isNegative = duration.asMilliseconds() < 0;
    const totalSeconds = Math.abs(duration.asSeconds());
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    // Format the delay as HH:mm:ss
    const formatted = `${isNegative ? "-" : ""}${hours
      .toString()
      .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;

    // If the delay is negative (early arrival), return "No Delay"
    return isNegative ? "No Delay" : formatted;
  }
  const fetchFlightDetails = async (hub_code, flightNumber, flightDate) => {
    try {
      const response = await axios.post(
        `${base_url.api1}/tourapp/flight_details`,
        {
          airline_code: hub_code,
          flight_number: flightNumber,
          flight_date: flightDate,
        }
      );
      setbackdropopen(false);
      console.log("response", response.data.Flight[0]);
      const flight = response.data.Flight[0];
      const flighDelay = calculateFlightDelay(flight);
      setDelay(flighDelay);
      setPastFlightData(response?.data?.Flight[0]);
    } catch (error) {
      Swal.fire({
        title: "No Flight Data",
        text: "Flight data is not available at the moment. Please try again later.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      setbackdropopen(false);
      setPastFlightData(null);
      setAirline(null);
      setDelay("N/A");
      activeTab === "past" ? resetPastForm() : resetFutureForm();
    }
  };

  const onSubmit = (data) => {
    setPastFlightData(null);
    try {
      setbackdropopen(true);
      fetchFlightDetails(
        data.airline.hub_code,
        data.flightNumber,
        data.dateOfTravel
      );
    } catch (error) {
      setbackdropopen(false);
    }

    // setSearchData(data);

    // activeTab === "past" ? resetPastForm() : resetFutureForm();
  };
  const onError = (error) => {
    console.log("ERROR");
  };

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Nav />

      <div className="container ">
        <div>
          <div
            class="page-head d-flex justify-content-between p-4"
            style={{ marginTop: "1.3rem" }}
          >
            <ul class="breadcrums">
              <Link to="/" class="b-link">
                Home
              </Link>
              <span className="s_span ">&raquo;</span>
              {/* <Link to="#" class="b-link">
              Linsoa – Fly N Claim
            </Link>
            <span className="s_span ">&raquo;</span> */}
              <Link to="#" class="b-link active">
                Flight Status
              </Link>
            </ul>
          </div>
          <div>
            {/* <div className="tabs">
              {tabs.map((tab) => (
                <div
                  key={tab.id}
                  onClick={() => handleTabClick(tab.id)}
                  className={`tab ${activeTab === tab.id ? "active-tab" : ""}`}
                >
                  {tab.label}
                </div>
              ))}
            </div> */}

            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="card shadow">
                  <div className="card-body">
                    {/* Form for search */}
                    {activeTab === "past" && (
                      <form onSubmit={handlePastSubmit(onSubmit, onError)}>
                        <Row>
                          <Col md={4}>
                            <div>
                              <label for="airline">Airline</label>
                              <Controller
                                name="airline"
                                control={airlineControl}
                                rules={{ required: !airline }}
                                render={({ field }) => (
                                  <AsyncSelect
                                    {...field}
                                    cacheOptions
                                    defaultOptions={defaultAirlineOptions}
                                    loadOptions={loadAirlineOptions}
                                    placeholder="Select Airline"
                                    value={
                                      airline
                                        ? {
                                            label: airline.label,
                                            value: airline.label,
                                          }
                                        : null
                                    }
                                    onChange={(selectedOption) => {
                                      setAirline(selectedOption);
                                      field.onChange(selectedOption);
                                    }}
                                  />
                                )}
                              />
                              {pastErrors.airline && (
                                <span className="text-danger">
                                  {pastErrors.airline.message}
                                </span>
                              )}
                            </div>
                          </Col>
                          <Col md={4}>
                            <div>
                              <label for="flightNumber">Flight Number</label>
                              <input
                                type="text"
                                id="flightNumber"
                                className="form-control"
                                placeholder="Enter flight number"
                                {...pastRegister("flightNumber", {
                                  required: "Flight number is required.",
                                })}
                              />
                              {pastErrors.flightNumber && (
                                <span className="text-danger">
                                  {pastErrors.flightNumber.message}
                                </span>
                              )}
                            </div>
                          </Col>
                          <Col md={4}>
                            <div>
                              <label for="dateOfTravel">Date of Travel</label>
                              <input
                                type="date"
                                id="dateOfTravel"
                                className="form-control"
                                {...pastRegister("dateOfTravel", {
                                  required: "Date of travel is required.",
                                })}
                              />
                              {pastErrors.dateOfTravel && (
                                <span className="text-danger">
                                  {pastErrors.dateOfTravel.message}
                                </span>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Button type="submit" color="primary">
                          Search
                        </Button>
                      </form>
                    )}

                    {/* {activeTab === "future" && (
                      <form onSubmit={handleFutureSubmit(onSubmit)}>
                        <Row>
                          <Col md={4}>
                            <div>
                              <label for="airportName">Airport Name</label>
                              <Controller
                                name="airportName"
                                control={airportControl}
                                rules={{ required: true }}
                                render={({ field }) => (
                                  <AsyncSelect
                                    {...field}
                                    cacheOptions
                                    defaultOptions={defaultAirportOptions}
                                    loadOptions={loadAirportOptions}
                                    placeholder="Select Airport"
                                  />
                                )}
                              />
                              {futureErrors.airportName && (
                                <span className="text-danger">
                                  {futureErrors.airportName.message}
                                </span>
                              )}
                            </div>
                          </Col>
                          <Col md={4}>
                            <div>
                              <label for="flightNumber">Flight Number</label>
                              <input
                                type="text"
                                id="flightNumber"
                                placeholder="Enter flight number"
                                className="form-control"
                                {...futureRegister("flightNumber", {
                                  required: "Flight number is required.",
                                })}
                              />
                              {futureErrors.flightNumber && (
                                <span className="text-danger">
                                  {futureErrors.flightNumber.message}
                                </span>
                              )}
                            </div>
                          </Col>
                          <Col md={4}>
                            <div>
                              <label for="dateOfTravel">Date of Travel</label>
                              <input
                                type="date"
                                id="dateOfTravel"
                                className="form-control"
                                {...futureRegister("dateOfTravel", {
                                  required: "Date of travel is required.",
                                })}
                              />
                              {futureErrors.dateOfTravel && (
                                <span className="text-danger">
                                  {futureErrors.dateOfTravel.message}
                                </span>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Button type="submit" color="primary">
                          Search
                        </Button>
                      </form>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
            {pastFlightData && (
              <>
                {/* <div className="row justify-content-center">
                  <div className="col-md-8">
                    <div className="card shadow">
                      <div className="card-body"></div>
                    </div>
                  </div>
                </div> */}
                <div style={{ marginBottom: "15%" }}>
                  <div
                    className="card text-white bg-success mb-3"
                    style={{
                      maxWidth: "80%",
                      margin: "2rem auto",
                      borderRadius: "8px",
                    }}
                  >
                    <div className="card-header d-flex flex-wrap justify-content-between align-items-start">
                      {/* Flight Info */}
                      <div className="mb-3 mb-md-0">
                        <h5 className="mb-0">
                          {pastFlightData?.flight?.iata
                            ? `${pastFlightData.flight.iata} ${
                                pastFlightData?.airline?.name || "N/A"
                              }`
                            : "N/A"}
                        </h5>
                      </div>

                      {/* Departure Info */}
                      <div className="mb-3 mb-md-0">
                        <h5 className="mb-0">
                          {pastFlightData?.departure?.iata || "N/A"}
                        </h5>
                        <small>
                          {pastFlightData?.departure?.airport || "N/A"}
                        </small>
                        <br />
                        <small>
                          <strong>Date:</strong>{" "}
                          {pastFlightData?.departure?.actual
                            ? moment(pastFlightData.departure.actual).format(
                                "MM-DD-YYYY"
                              )
                            : "N/A"}
                        </small>
                      </div>

                      {/* Arrival Info */}
                      <div className="mb-3 mb-md-0">
                        <h5 className="mb-0">
                          {pastFlightData?.arrival?.iata || "N/A"}
                        </h5>
                        <small>
                          {pastFlightData?.arrival?.airport || "N/A"}
                        </small>
                        <br />
                        <small>
                          <strong>Date:</strong>{" "}
                          {pastFlightData?.arrival?.actual
                            ? moment(pastFlightData.arrival.actual).format(
                                "MM-DD-YYYY"
                              )
                            : "N/A"}
                        </small>
                      </div>

                      {/* Flight Status */}
                      <div className="mb-3 mb-md-0">
                        <h5 className="mb-0">Status</h5>
                        <small>
                          {pastFlightData?.flight_status
                            ? pastFlightData.flight_status.toUpperCase()
                            : "N/A"}{" "}
                        </small>
                        <br />
                        <small
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          <strong style={{ color: "white" }}>Delay:</strong>{" "}
                          {delay || "No Delay"}
                        </small>
                      </div>
                    </div>

                    <div className="card-body">
                      <div className="row">
                        {/* Departure Info */}
                        <div className="col-12 col-md-6 mb-3">
                          <h5>Departure</h5>
                          <p className="mb-1">
                            <strong>Scheduled:</strong>{" "}
                            {pastFlightData?.departure?.scheduled
                              ? formatTime(
                                  pastFlightData.departure.scheduled,
                                  pastFlightData?.departure?.timezone
                                )
                              : "N/A"}
                          </p>
                          <p className="mb-1">
                            <strong>Actual:</strong>{" "}
                            {pastFlightData?.departure?.actual
                              ? formatTime(
                                  pastFlightData.departure.actual,
                                  pastFlightData?.departure?.timezone
                                )
                              : "N/A"}
                          </p>
                          <p className="mb-1">
                            <strong>Terminal:</strong>{" "}
                            {pastFlightData?.departure?.terminal || "N/A"}
                          </p>
                          <p>
                            <strong>Gate:</strong>{" "}
                            {pastFlightData?.departure?.gate || "N/A"}
                          </p>
                        </div>

                        {/* Arrival Info */}
                        <div className="col-12 col-md-6 mb-3">
                          <h5>Arrival</h5>
                          <p className="mb-1">
                            <strong>Scheduled:</strong>{" "}
                            {pastFlightData?.arrival?.scheduled
                              ? formatTime(
                                  pastFlightData.arrival.scheduled,
                                  pastFlightData?.arrival?.timezone
                                )
                              : "N/A"}
                          </p>
                          <p className="mb-1">
                            <strong>Actual:</strong>{" "}
                            {pastFlightData?.arrival?.actual
                              ? formatTime(
                                  pastFlightData.arrival.actual,
                                  pastFlightData?.arrival?.timezone
                                )
                              : "N/A"}
                          </p>
                          <p className="mb-1">
                            <strong>Terminal:</strong>{" "}
                            {pastFlightData?.arrival?.terminal || "N/A"}
                          </p>
                          <p>
                            <strong>Baggage:</strong>{" "}
                            {pastFlightData?.arrival?.baggage || "N/A"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlightDetailsPage;
