// ViewFileModal.jsx

import React from "react";
import Modal from "react-modal";
import {
  useStyles,
  customStyles,
  customStylesAttachment,
  customStylesAttachmentBig,
} from "../../Utilities/CSSUtilities";

const ViewFileModal = ({ isOpen, closeModal, modalTitle, modalFileUrl }) => {
  const isImageFile = (url) => {
    // Example function to check if the URL ends with a common image file extension
    return /\.(jpeg|jpg|png|gif)$/i.test(url);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStylesAttachment}
      contentLabel="Viexw File Modal"
    >
      <div>
        <h4>{modalTitle}</h4>
        <hr />
        {isImageFile(modalFileUrl) ? (
          <div>
            <img src={modalFileUrl} alt={modalTitle} className="img-fluid" />
          </div>
        ) : null}
        <hr />
        <div style={{ textAlign: "right" }}>
          <button className="btn btn-secondary" onClick={closeModal}>
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ViewFileModal;
