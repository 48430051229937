import React, { useState } from "react";
import Modal from "react-modal";
import axios from "axios";
import base_url from "../../api/bootapi";
import zIndex from "@mui/material/styles/zIndex";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "400px", // Adjust the width as needed
    padding: "20px",
    height: "auto",
    borderRadius: "8px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: "1000",
  },
};
const UploadModal = ({
  isOpen,
  onRequestClose,
  claimId,
  isMultiple,
  docType,
}) => {
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [backdropopen, setbackdropopen] = useState(false);

  const getLabel = () => {
    if (isMultiple) {
      return "Documents";
    } else if (docType === "poa") {
      return "POA";
    } else if (docType === "agreement") {
      return "Agreement";
    } else if (docType === "passport") return "Passport";
    else {
      return "Document";
    }
  };

  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files);
  };
  console.log("ISMULTIPLE", isMultiple);
  console.log("DOCTYPE", docType);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!selectedFiles) {
      Swal.fire({
        title: "Please select files to upload.",
        icon: "warning",
        confirmButtonText: "OK",
      });

      return;
    }
    setbackdropopen(true);
    const formData = new FormData();

    if (isMultiple) {
      const formObject = {
        claim_id: claimId,
      };
      const jsonFormObject = JSON.stringify(formObject);

      formData.append("data", jsonFormObject);

      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("document", selectedFiles[i]);
      }

      // Example URL for file upload
      const uploadUrl = `${base_url.api1}/tourapp/claim_doc`; // Replace with your actual upload URL

      axios
        .post(uploadUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log("Upload successful:", response.data);
          setbackdropopen(false);
          onRequestClose();

          const count = response.data.data.length;

          Swal.fire({
            icon: "success",
            title: "Success",
            text: `Document uploaded successfully. ${count} document(s) uploaded.`,
          });

          // Add any additional logic here after successful upload

          // Add any additional logic here after successful upload
        })
        .catch((error) => {
          console.error("Error uploading files:", error);
          setbackdropopen(false);
          onRequestClose();
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to upload documents. Please try again.",
          });

          // Handle error states as needed
        });
    } else if (!isMultiple && docType) {
      const formObject = {};
      const jsonFormObject = JSON.stringify(formObject);

      formData.append("data", jsonFormObject);
      let doc;

      if (docType === "poa") {
        doc = "power_of_attorney";
      } else if (docType === "passport") {
        doc = "passport";
      } else {
        doc = "agreement";
      }
      formData.append(doc, selectedFiles[0]);

      // Example URL for file upload
      const uploadUrl = `${base_url.api1}/tourapp/compensation_request?id=${claimId}`; // Replace with your actual upload URL

      axios
        .put(uploadUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log("Upload successful:", response.data);
          setbackdropopen(false);
          onRequestClose();

          Swal.fire({
            icon: "success",
            title: "Success",
            text: `Document uploaded successfully.`,
          });

          // Add any additional logic here after successful upload

          // Add any additional logic here after successful upload
        })
        .catch((error) => {
          console.error("Error uploading files:", error);
          setbackdropopen(false);
          onRequestClose();
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to upload documents. Please try again.",
          });

          // Handle error states as needed
        });
    }
  };

  const handleClose = () => {
    setSelectedFiles(null); // Clear selected files
    onRequestClose(); // Close the modal
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Upload Documents"
        style={customModalStyles}
      >
        <h4 className="text-left">Upload {getLabel()}</h4>
        <form onSubmit={handleSubmit}>
          <div className="d-flex justify-content-start">
            <div className="d-flex flex-column">
              {" "}
              {isMultiple && <label>Select multiple files to upload:</label>}
              {isMultiple ? (
                <input
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  className="form-control"
                />
              ) : (
                <input
                  type="file"
                  onChange={handleFileChange}
                  className="form-control"
                />
              )}
            </div>
          </div>
          <div
            className="d-flex justify-content-end"
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <div style={{ marginRight: "15px" }}>
              <button className="btn btn-success" type="submit">
                Upload
              </button>
            </div>
            <div>
              <button
                className="btn btn-primary"
                type="button"
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default UploadModal;
