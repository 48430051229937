import React, { useState } from "react";
import Modal from "react-modal";
import axios from "axios";
import Swal from "sweetalert2";
import zIndex from "@mui/material/styles/zIndex";
import base_url from "../../api/bootapi";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "450px", // Adjust the width as needed
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: "1000",
  },
};

const MessageModal = ({
  isOpen,
  onRequestClose,
  claimId,
  previousNote,
  getStatusDetails,
}) => {
  const [note, setNote] = useState("");
  const [backdropopen, setbackdropopen] = useState(false);

  const getCurrentDateTime = () => {
    return moment().format("h:mm A MMMM D, YYYY");
  };

  const addCustomerNote = (notes, customerNote) => {
    const dateTime = getCurrentDateTime();
    const newNote = {
      user: "customer",
      note: customerNote,
      timestamp: dateTime,
    };

    if (!notes) {
      notes = [];
    }

    return [...notes, newNote];
  };

  const handleInputChange = (e) => {
    setNote(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const trimmedNote = note.trim();
    if (trimmedNote === "") {
      return;
    }
    const updatedNotes = addCustomerNote(previousNote, note);

    setbackdropopen(true);

    const formData = new FormData();

    const formObject = {
      note: updatedNotes,
    };
    const jsonFormObject = JSON.stringify(formObject);

    formData.append("data", jsonFormObject);

    const sendMessageUrl = `${base_url.api1}/tourapp/compensation_request?id=${claimId}`; // Replace with your actual URL

    axios
      .put(sendMessageUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setbackdropopen(false);
        //console.log("Message sent successfully:", response.data);
        setNote("");
        onRequestClose();
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Message sent successfully",
        });
        getStatusDetails();
      })
      .catch((error) => {
        setbackdropopen(false);
        setNote("");

        onRequestClose();

        console.error("Error sending message:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to send message. Please try again.",
        });
      });
  };

  const handleClose = () => {
    setNote(""); // Clear the note
    onRequestClose(); // Close the modal
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        style={customModalStyles}
        contentLabel="Send Message"
      >
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
          Send Message to Lawyer
        </h2>
        <form>
          <div style={{ marginBottom: "20px" }}>
            <label style={{ display: "block", marginBottom: "5px" }}>
              Message:
            </label>
            <textarea
              value={note}
              onChange={handleInputChange}
              rows="4"
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "5px",
                borderColor: "#ccc",
              }}
              className="form-control"
            />
          </div>
          <div
            className="d-flex justify-content-end"
            style={{ textAlign: "center" }}
          >
            <button
              onClick={(e) => handleSubmit(e)}
              style={{
                backgroundColor: "#007bff",
                color: "white",
                padding: "10px 20px",
                borderRadius: "5px",
                marginRight: "10px",
                border: "none",
                cursor: "pointer",
              }}
            >
              Submit
            </button>
            <button
              type="button"
              onClick={handleClose}
              style={{
                backgroundColor: "#dc3545",
                color: "white",
                padding: "10px 20px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default MessageModal;
